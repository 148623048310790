import React from 'react'
import Layout from '../layouts/layout.js'
import Helmet from 'react-helmet'
import Pagetitle from '../components/Pagetitle'

const Gallery = () => (
  <Layout>
    <div>
      <Helmet title="Gallery" />
      <Pagetitle title="Gallery"/>
    </div>
  </Layout>
)

export default Gallery
